import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const secretsManagerPlugin = createPlugin({
  id: 'secrets-manager',
  routes: {
    root: rootRouteRef,
  },
});

export const SecretsManagerPage = secretsManagerPlugin.provide(
  createRoutableExtension({
    name: 'SecretsManagerPage',
    component: () =>
      import('./components/SecretsManagerPageComponent').then(
        m => m.SecretsManagerPageComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
